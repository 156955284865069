class FaqCollapse {
    load() {
        this.FAQNodes = document.getElementsByClassName('faq-wrapper');
        this.onClickQuestionEvent = this.onClickQuestion;

        for (const FAQNode of this.FAQNodes) {
            FAQNode.addEventListener('click', this.onClickQuestionEvent);
        }
    }

    remove() {
        for (const FAQNode of this.FAQNodes) {
            FAQNode.removeEventListener('click', this.onClickQuestionEvent);
        }
    }

    onClickQuestion(event) {
        const faqButton = event.currentTarget;

        let questions = document.querySelectorAll('.faq-wrapper');
        questions.forEach(question => {
            question.classList.remove('faq-wrapper-active');
            question.querySelector('use').setAttribute('xlink:href', 'https://media.emit.reviews/images/plus-dark.svg#plus-dark');
        });

        faqButton.classList.toggle('faq-wrapper-active');
        faqButton.querySelector('use').setAttribute('xlink:href', 'https://media.emit.reviews/images/minus-purple.svg#minus');
    }
}

export default FaqCollapse
